import React, { useEffect, useRef, useState } from "react";
import { subjectsOptions } from "../../auth/constants/options";
import { Button, Checkbox } from "../../../components";
import Cart from "../../auth/onboarding/Cart";
import envConfig from "../../../config/envConfig";
import Step1Instructions from "../../auth/onboarding/Step1Instructions";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { auth, db } from "../../../config/firebaseConfig";
import axios from "axios";
import { ImSpinner2 } from "react-icons/im";
import { GAEventTrack } from "../../../hooks/GA_events";
import { scrollToView } from "../../../constant/scrollToView";

const AddSubjects = ({ studentDetails }) => {
  const [subjects, setSubjects] = useState();
  const [addedSubjects, setAddedSubjects] = useState([]);
  const [amount, setAmount] = useState(0);
  const [country, setCountry] = useState("IN");
  const [cartPositon, setCartPosition] = useState("absolute top-0 -right-60");
  const [acknowledgeChecked, setAcknowledgeChecked] = useState(false);
  const cartRef = useRef();
  const subjectRef = useRef();
  const acknowledgeErrorRef = useRef();
  const submitErrorRef = useRef();

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [retryModule, setRetryModule] = useState(false);

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await fetch(
          `https://ipinfo.io/json?token=${envConfig?.ipInfoKey}`
        );
        const data = await response.json();
        const country = data.country;
        setCountry(country);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
        setCountry("IN");
      }
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    if (studentDetails) {
      setSubjects(studentDetails?.subjects?.map((sub) => sub.subject));
    }
  }, [studentDetails, setSubjects]);

  const handleSaveAndPay = async (e) => {
    e.preventDefault();
    setError(null);

    // 1. validation
    if (!acknowledgeChecked) {
      setError((prev) => ({
        ...prev,
        acknowledgement: "Please check the acknowledge",
      }));
      scrollToView(acknowledgeErrorRef?.current);
      return;
    }

    if (addedSubjects?.length === 0) {
      setError((prev) => ({ ...prev, subjects: "Please select subjects" }));
      scrollToView(subjectRef?.current);
      return;
    }

    // 2. update the student doc
    const subjectsAdded = addedSubjects?.map((subject) => {
      const examCode = `P${subject?.slice(0, 1)}O-${uuidv4().substring(
        0,
        5
      )}`?.toUpperCase();
      return {
        subject: subject,
        examCode: examCode,
      };
    });

    const studentData = {
      subjects: [...studentDetails?.subjects, ...subjectsAdded],
      updatedAt: serverTimestamp(),
    };

    const paymentFor = [
      {
        participantId: studentDetails?.enrollmentNo,
        subjects: addedSubjects,
      },
    ];

    setLoading((prev) => ({ ...prev, submit: true, module: true }));

    //GA event tracking
    GAEventTrack({
      event: "add_subjects_save_and_pay",
      data: {
        uid: auth?.currentUser?.uid,
        email: auth?.currentUser?.email,
        studentId: studentDetails?.enrollmentNo,
      },
    });

    try {
      await handlePayment(amount, paymentFor, studentData);
    } catch (error) {
      setError((prev) => ({
        ...prev,
        submit: error.message,
      }));
      scrollToView(submitErrorRef?.current);
      setLoading((prev) => ({ ...prev, module: false }));
    } finally {
      setLoading((prev) => ({ ...prev, submit: false }));
    }
  };

  const handlePayment = async (amount, paymentFor, studentData) => {
    const backendUrl = `${envConfig.backendUrl}`;
    const currency = country === "IN" ? "INR" : "USD";

    try {
      const {
        data: { key },
      } = await axios.get(`${backendUrl}?action=getkey`);

      const {
        data: { order },
      } = await axios.post(`${backendUrl}?action=checkout`, {
        amount,
        currency,
        userId: auth?.currentUser?.uid,
        userEmail: auth?.currentUser?.email,
        childCount: 1,
      });

      const options = {
        key,
        order_id: order.id,
        amount: order.amount,
        currency: order.currency,
        name: envConfig.appFullName,
        description: envConfig.appFullName,
        image: "/img/appLogo.png",
        prefill: {
          name: studentDetails?.parent?.name,
          email: studentDetails?.parent?.email,
          contact: studentDetails?.parent?.phone,
        },
        notes: {
          address: "",
          uid: auth?.currentUser?.uid,
        },
        theme: {
          color: "#E74C3C",
        },
        handler: async (response) => {
          try {
            await paymentSuccessHandler(
              response,
              backendUrl,
              order,
              paymentFor,
              studentData
            );
          } catch {
            console.log(error);
            setError((prev) => ({
              ...prev,
              submit: error?.message,
            }));
          }
        },

        modal: {
          ondismiss: async () => await onPaymentModalDismiss(backendUrl, order),
        },
      };

      const razor = new window.Razorpay(options);
      // Handle payment failure
      razor.on("payment.failed", async function (response) {
        console.log(response);
        //GA event tracking
        GAEventTrack({
          event: "addSub_payment_failed",
          data: {
            uid: auth?.currentUser?.uid,
            email: auth?.currentUser?.email,
          },
        });
      });

      razor.open();
    } catch (error) {
      // GA event tracking
      GAEventTrack({
        event: "addSubject_payment_init_failed",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
          error: error.code,
        },
      });
      throw new Error(
        "Something went wrong, while initiating your payment. Please try again"
      );
    }
  };

  const onPaymentModalDismiss = async (backendUrl, order) => {
    await axios.post(`${backendUrl}?action=orderDismissed`, {
      orderId: order.id,
    });
    setLoading((prev) => ({ ...prev, module: false }));
    // GA event tracking
    GAEventTrack({
      event: "addSub_payment_dismissed",
      data: {
        uid: auth?.currentUser?.uid,
        email: auth?.currentUser?.email,
      },
    });
  };

  const paymentSuccessHandler = async (
    response,
    backendUrl,
    order,
    paymentFor,
    studentData
  ) => {
    console.log("handler -- ", response);

    // Payment verification
    try {
      const verificationResponse = await axios.post(
        `${backendUrl}?action=paymentVerification`,
        {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          userId: auth?.currentUser?.uid,
          userEmail: auth?.currentUser?.email,
          receipt: order?.receipt,
          amount: order?.amount,
          currency: order?.currency,
          childCount: 1,
        }
      );
      if (verificationResponse?.data?.status === "success") {
        console.log("Payment verification successful");
        try {
          await savePaymentDetails(response, order, paymentFor, studentData);
          window.location.href = `/parentDashboard/${studentDetails?.enrollmentNo}?ref=${response.razorpay_payment_id}`;
        } catch (error) {
          console.log("Error saving payment details:", error);

          setLoading((prev) => ({ ...prev, module: false }));

          localStorage.setItem(
            "practido-addSub-retryData",
            JSON.stringify({
              response,
              order,
              paymentFor,
              studentData,
            })
          );
          setRetryModule(true);

          //GA event tracking
          GAEventTrack({
            event: "addSub_payment_data__saving_failed",
            data: {
              uid: auth?.currentUser?.uid,
              email: auth?.currentUser?.email,
            },
          });
        }
      } else {
        //GA event tracking
        GAEventTrack({
          event: "addSub_payment_verification_failed",
          data: {
            uid: auth?.currentUser?.uid,
            email: auth?.currentUser?.email,
          },
        });
        throw new Error("Payment verification failed");
      }
    } catch (error) {
      throw new Error(
        ("server error - Somethig went wrong while verifiying your payment.",
        error)
      );
    }
  };

  const savePaymentDetails = async (
    response,
    order,
    paymentFor,
    studentData
  ) => {
    try {
      const studentDocRef = doc(
        db,
        "participants",
        studentDetails?.enrollmentNo
      );
      // update to firebase document
      await updateDoc(studentDocRef, studentData);
    } catch (error) {
      console.log(error);
      throw error;
    }

    // payment details for payment collection
    const paymentDetails = {
      orderId: response.razorpay_order_id,
      amount: order.amount,
      currency: order.currency,
      paymentAt: Timestamp.fromDate(new Date()),
      paymentId: response.razorpay_payment_id,
      paymentSignature: response.razorpay_signature,
      paymentFor: paymentFor,
      schoolId: studentDetails?.parent?.schoolId || null,
    };

    // Add payment details to Firebase Firestore
    try {
      const paymentRef = await addDoc(
        collection(db, "payments"),
        paymentDetails
      );

      // payment details for parent collection
      const paymentDetailsParentDoc = {
        orderId: response.razorpay_order_id,
        amount: order.amount,
        currency: order.currency,
        paymentAt: Timestamp.fromDate(new Date()),
        paymentRef: paymentRef.id,
        paymentFor: paymentFor,
      };
      // update data to parent db
      const parentDocRef = doc(db, "parents", studentDetails?.parent?.id);
      await updateDoc(parentDocRef, {
        paymentDetails: [
          ...(studentDetails?.parent?.paymentDetails || []),
          paymentDetailsParentDoc,
        ],
      });

      // update payment status to participtant db - add in subjects array
      try {
        const participantDocRef = doc(
          db,
          "participants",
          studentDetails?.enrollmentNo
        );
        const docSnap = await getDoc(participantDocRef);
        if (docSnap.exists()) {
          let subjects = docSnap.data().subjects;
          subjects = subjects.map((subject) => ({
            ...subject,
            payment: {
              status: "paid",
              paymentRef: paymentRef.id,
            },
          }));
          await updateDoc(participantDocRef, {
            subjects: subjects,
          });
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        throw error;
      }

      //GA event tracking
      GAEventTrack({
        event: "add_subject_payment_data_saved",
        data: {
          uid: auth?.currentUser?.uid,
          email: auth?.currentUser?.email,
          studentId: studentDetails?.id,
        },
      });
    } catch (e) {
      console.log(error);
      // setError((prev) => ({ ...prev, submit: error.message }));
      setLoading((prev) => ({ ...prev, module: false }));
      throw error;
    }
  };

  const retryUpdatingPaymentData = async () => {
    const data = JSON.parse(localStorage.getItem("practido-addSub-retryData"));
    if (data) {
      const response = data.response;
      const order = data.order;
      const paymentFor = data.paymentFor;
      const studentData = data.studentData;
      try {
        await savePaymentDetails(response, order, paymentFor, studentData);
        setRetryModule(false);
        window.location.href = `/parentDashboard/${studentDetails?.enrollmentNo}?ref=${response.razorpay_payment_id}`;
      } catch (error) {
        console.log("Error saving payment details:", error);
        localStorage.setItem(
          "practido-addSub-retryData",
          JSON.stringify({
            response,
            order,
            paymentFor,
          })
        );
        setRetryModule(true);
      }
    }
  };

  return (
    <div className="relative onboarding-step max-w-[990px] mt-0 mb-[50px] mx-auto !space-y-6">
      {loading?.module && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/20 backdrop-blur-sm flex items-center justify-center">
          <div className=" p-20 py-10 bg-white shadow-md rounded-xl text-gray-500 flex flex-col items-center gap-4">
            <ImSpinner2 className="animate-spin w-8 h-8" />
            <p className="text-2xl font-bold ">Please Wait</p>
          </div>
        </div>
      )}
      {retryModule && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-black/20 backdrop-blur-sm flex items-center justify-center">
          <div className="p-10 mx-10 w-[32rem] bg-white shadow-md rounded-xl flex flex-col items-center gap-4">
            <p className="text-xl font-bold text-center text-gray-600 ">
              Payment was successful, but we encountered an error while saving
              payment details.
            </p>
            <Button
              version="secondary"
              text="Try again"
              handleClick={retryUpdatingPaymentData}
            />
          </div>
        </div>
      )}
      <div
        className={`summary-card for-mobile w-[450px] z-40 right-[10%] -top-10 h-full absolute `}
      >
        <div
          className={`summary-card ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={[{ subjects: addedSubjects }]}
            amount={amount}
            setAmount={setAmount}
            participantName={studentDetails?.name}
          />
        </div>
      </div>
      <h1 className="text-2xl font-bold ">
        Participant : {studentDetails?.name}
      </h1>
      <div className="row" ref={subjectRef}>
        <div className="col-md-6">
          <div className="form-group">
            <div className="flex gap-4">
              <label className="">
                Select Subject<sup>*</sup>
              </label>
            </div>
            <div className="flex justify-start gap-12">
              {subjectsOptions
                ?.filter((sub) => !subjects?.includes(sub.value))
                .map((sub) => (
                  <div className="relative" key={sub.id}>
                    <div className="absolute top-[-2rem] right-[-18px]">
                      <Checkbox
                        checkId={sub.value}
                        checkName={sub.value}
                        checkValue={sub.value}
                        checked={addedSubjects?.includes(sub?.value)}
                        handleChange={() => {
                          setAddedSubjects((prev) =>
                            prev?.includes(sub.value)
                              ? prev?.filter((s) => s !== sub.value)
                              : [...prev, sub.value]
                          );
                          setError((prev) => ({ ...prev, subjects: "" }));
                        }}
                      />
                    </div>
                    <label
                      htmlFor={sub.value}
                      className="flex flex-col items-center justify-center gap-4 cursor-pointer"
                    >
                      <div
                        className={`w-[126px] h-[126px] rounded-2xl flex items-center justify-center subject-card
                                  ${
                                    addedSubjects?.includes(sub.value)
                                      ? "bg-[#F2B010] shadow-[0px_4px_0px_0px_#B88815]"
                                      : "bg-[#ECF0F1] shadow-[0px_4px_0px_0px_#A9A9A9] hover:bg-[#E5E5F1] "
                                  }
                                `}
                      >
                        <img src={sub.icon} alt={sub.label} />
                      </div>
                      <div className="font-bold text-[16px] text-[#34495E]">
                        {sub.label}
                      </div>
                    </label>
                  </div>
                ))}
            </div>
            {error?.subjects && (
              <div className="text-red-500 mb-2">{error?.subjects}</div>
            )}

            <div className="text-red-500 mb-2" ref={submitErrorRef}>
              {error?.submit || null}
            </div>
          </div>
        </div>
      </div>
      <div className="my-8 imp-inst">
        <Step1Instructions
          checked={acknowledgeChecked}
          setChecked={setAcknowledgeChecked}
          acknowledgeErrRef={acknowledgeErrorRef}
          error={error?.acknowledgement}
        />
      </div>
      <div
        className={`summary-card desktop-view w-[450px] z-40 right-[10%] -top-10 h-full absolute `}
      >
        <div
          className={`summary-card ${cartPositon} w-[450px] space-y-4`}
          ref={cartRef}
        >
          <Cart
            country={country}
            participantDetailArray={[{ subjects: addedSubjects }]}
            amount={amount}
            setAmount={setAmount}
            participantName={studentDetails?.name}
          />
          <Button
            loading={loading?.submit}
            text="Save & Pay"
            version="secondary"
            handleClick={handleSaveAndPay}
          />
        </div>
      </div>
    </div>
  );
};

export default AddSubjects;
