import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../config/firebaseConfig";
import { FaCaretDown } from "react-icons/fa6";
import { LuLogOut } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { LiaUserEditSolid } from "react-icons/lia";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { GAEventTrack } from "../../hooks/GA_events";

const Header = ({ placement, dashboard, name, photoUrl }) => {
  const [userAuth] = useAuthState(auth);
  const headerRef = useRef(null);
  const menuBtnRef = useRef(null);
  const menuRef = useRef(null);
  const menuBlocksRef = useRef(null);
  const navHeaderRef = useRef(null);
  const subHeaderRef = useRef(null);
  const profileMenuRef = useRef(null);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const path = location?.pathname;

  // useEffect(() => {
  //     window.addEventListener('scroll', () => {
  //         const scrollPositionToShowHeader = 50; // Change this value to set when the header should change color
  //         if (window.scrollY > scrollPositionToShowHeader) {
  //             headerRef.current.classList.add('header-scrolled');
  //         } else {
  //             headerRef.current.classList.remove('header-scrolled');
  //         }
  //     });
  // },[])

  useEffect(() => {
    const clickOutsideProfileMenu = (event) => {
      console.log(event.target);
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setProfileMenuOpen(false);
      }
    };
    if (profileMenuOpen) {
      document.addEventListener("mousedown", clickOutsideProfileMenu);
    } else {
      document.removeEventListener("mousedown", clickOutsideProfileMenu);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutsideProfileMenu);
    };
  }, [profileMenuOpen]);

  useEffect(function () {
    if (document.getElementById("feature-data")) {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 100) {
          if (subHeaderRef.current) {
            subHeaderRef.current.style.top = "0";
          }
        } else {
          if (subHeaderRef.current) {
            subHeaderRef.current.style.top = "80px";
          }
        }
      });
    }
  }, []);

  const activateMenu = (e) => {
    var self = e.currentTarget;
    if (menuRef.current.classList.contains("active")) {
      self.classList.remove("active");
      menuBlocksRef.current.classList.remove("active");
      menuRef.current.classList.remove("active");
      navHeaderRef.current.classList.remove("active");
    } else {
      self.classList.add("active");
      menuBlocksRef.current.classList.add("active");
      menuRef.current.classList.add("active");
      navHeaderRef.current.classList.add("active");
    }
  };

  const clickToScroll = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href");
    if (placement === "landingPage") {
      const targetElement = document.querySelector(targetId);
      const offset = targetElement.offsetTop;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
      if (menuBtnRef.current.classList.contains("active")) {
        menuRef.current.classList.remove("active");
        menuBlocksRef.current.classList.remove("active");
        menuBtnRef.current.classList.remove("active");
        navHeaderRef.current.classList.remove("active");
      } else {
        menuBtnRef.current.classList.add("active");
        menuBlocksRef.current.classList.add("active");
      }
    } else {
      navigate("/");
    }
  };

  function handleLogoClick() {
    // if (placement === "landingPage") {
    // }
    if (placement === "dashboard" && userAuth) {
      if (dashboard === "parent") {
        navigate("/parentDashboard");
      } else if (dashboard === "school") {
        navigate("/schoolDashboard");
      }
    } else {
      navigate("/");
    }

    // setTimeout(function () {
    window.scroll({ top: 0, behavior: "smooth" });
    // }, 100);
  }

  const socialList = [
    {
      name: "facebook",
      imgSrc: "/img/social/fb.png",
      href: "https://www.facebook.com/profile.php?id=100094743883639",
    },
    {
      name: "instagram",
      imgSrc: "/img/social/insta.png",
      href: "https://www.instagram.com/olympiadpracticeapp?igshid=YzVkODRmOTdmMw==",
    },
    {
      name: "twitter",
      imgSrc: "/img/social/tw.png",
      href: "https://twitter.com/olympiad_p",
    },
    {
      name: "youtube",
      imgSrc: "/img/social/youtube.png",
      href: "https://youtube.com/@OlympiadPracticeApp?feature=shared",
    },
    {
      name: "whatsapp",
      imgSrc: "/img/social/whatapp.png",
      href: "https://whatsapp.com/channel/0029Va50kJ33rZZbb9X3za1q",
    },
    {
      name: "email",
      imgSrc: "/img/social/email.png",
      href: "mailto:hello@practido.com",
    },
  ];

  const logSocialGA = (name) => {
    GAEventTrack({ event: `social_${name}_btn`, data: { button_name: name } });
  };

  return (
    <section id="header" className="header-section olympiad-header">
      <header className="" ref={headerRef}>
        <nav className="navbar navbar-light fixed-top" style={{ zIndex: 1031 }}>
          <div className="container">
            <div className="nav-header" ref={navHeaderRef}>
              <div className="navbar-brand logo" onClick={handleLogoClick}>
                <img src="/img/logo.png" alt="logo" />
              </div>

              <div className="mobile-playstore d-none">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                  className="menu__link btn white-btn"
                >
                  <span>
                    <img src="/img/new-home/play-icon.png" alt="google play" />{" "}
                    Google Play
                  </span>
                </a>
              </div>
              <div
                className="menu-icon"
                ref={menuBtnRef}
                onClick={activateMenu}
              >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </div>
            <div className="menu menu__m-view" ref={menuRef}>
              {(placement === "register" || placement === "dashboard") && (
                <ul className="reg-header menu-list menu-list--left mb-header-nav">
                  {userAuth?.displayName ? (
                    <li className="menu__item mega-menu-item">
                      <NavLink
                        to={
                          dashboard === "school"
                            ? "/schoolDashboard"
                            : "/parentDashboard"
                        }
                        // className={({ isActive }) =>
                        //   `menu__link ${isActive ? "active" : "not-active"}`
                        // }
                        className={`menu__link ${
                          path === "/schoolDashboard" ||
                          path === "/parentDashboard"
                            ? "active"
                            : "not-active"
                        }`}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  ) : (
                    <li className="menu__item mega-menu-item">
                      <NavLink
                        to="/register"
                        className={({ isActive }) =>
                          `menu__link ${isActive ? "active" : "not-active"}`
                        }
                      >
                        Practido Registration
                      </NavLink>
                    </li>
                  )}
                  {dashboard === "school" && userAuth ? (
                    <>
                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/schoolDashboard/registeredStudents"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          Registered
                        </NavLink>
                      </li>
                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/schoolDashboard/results"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          Results
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/faqLanding"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          FAQs
                        </NavLink>
                      </li>
                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/syllabusLanding"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          Syllabus
                        </NavLink>
                      </li>
                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/examLanding"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          Exam Dates
                        </NavLink>
                      </li>

                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/instructions"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          Instructions
                        </NavLink>
                      </li>
                      <li className="menu__item mega-menu-item">
                        <NavLink
                          to="/awardsLanding"
                          className={({ isActive }) =>
                            `menu__link ${isActive ? "active" : "not-active"}`
                          }
                        >
                          Awards
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              )}
              {placement === "dashboard" && userAuth && (
                <>
                  <div className="mobile-logout flex flex-col gap-3 px-2 lg:hidden absolute mgt20">
                    <div className="flex items-center gap-2">
                      <div className="flex flex-col">
                        <span className="font-semibold text-base">
                          {auth.currentUser.displayName}
                        </span>
                        <span className="text-sm">
                          {auth.currentUser.email
                            ? auth.currentUser.email
                            : auth.currentUser.phoneNumber}
                        </span>
                      </div>
                    </div>
                    <div
                      className="text-red-500 flex items-center gap-2 cursor-pointer"
                      onClick={() => {
                        signOut(auth);
                      }}
                    >
                      <LuLogOut className="w-6 h-6" />
                      <span className="font-bold">Logout</span>
                    </div>
                  </div>
                  <div className="gap-8 items-center hidden lg:flex">
                    <div
                      className="logout-dropdown relative primary-btn flex items-center px-6 gap-10 justify-between"
                      onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                    >
                      <span className="name font-semibold capitalize">
                        {userAuth?.displayName}
                      </span>
                      <FaCaretDown />
                      {profileMenuOpen && (
                        <div
                          className="absolute top-16 right-0 rounded-2xl bg-[#ECF0F1] shadow-[0px_4px_0px_0px_#A9A9A9] overflow-hidden"
                          ref={profileMenuRef}
                        >
                          <ul className="text-gray-700 font-bold">
                            <div className="flex gap-2 p-3 px-4 border-b-2 border-gray-300">
                              <div className="w-12 h-12 rounded-full bg-[#F2B010]/20 flex items-center justify-center">
                                <FaRegUser className="w-7 h-7 text-[#F2B010]" />
                              </div>
                              <div className="flex flex-col">
                                <span className="font-semibold capitalize">
                                  {auth.currentUser.displayName}
                                </span>
                                <span className="text-sm">
                                  {auth.currentUser.email
                                    ? auth.currentUser.email
                                    : auth.currentUser.phoneNumber}
                                </span>
                              </div>
                            </div>
                            {/* <li className="p-2 px-4 hover:bg-[#E5E5F1] mt-2 flex items-center gap-2">
                            <LiaUserEditSolid className="w-6 h-6" />
                            Edit Profile
                          </li>
                          <li className="p-2 px-4 hover:bg-[#E5E5F1] flex items-center gap-2">
                            <IoSettingsOutline className="w-6 h-6" />
                            Settings
                          </li> */}
                            <li
                              className="p-3 px-4 hover:bg-[#E5E5F1] mb-2 text-red-500 flex items-center gap-2"
                              onClick={() => {
                                signOut(auth);
                              }}
                            >
                              <LuLogOut className="w-6 h-6" />
                              Logout
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {placement === "landingPage" && (
                <ul className="menu-list menu-list--left mb-header-nav olympiad-header-list ">
                  <li className="d-none menu__item mega-menu-item">
                    <a
                      href="/"
                      // onClick={clickToScroll}
                      className="menu__link"
                    >
                      Home
                    </a>
                  </li>
                  <li className="d-none menu__item mega-menu-item">
                    <a href="/schoollanding" className="menu__link">
                      Schools
                    </a>
                  </li>
                  <li className="d-none menu__item mega-menu-item">
                    <a href="/parentlanding" className="menu__link">
                      Parents
                    </a>
                  </li>
                  <li className="d-none menu__item mega-menu-item">
                    <a href="/educatorlanding" className="menu__link">
                      Educators
                    </a>
                  </li>
                  <li className="d-none menu__item mega-menu-item">
                    <a href="/register" target="_blank" className="menu__link">
                      Practido Online Olympiad
                    </a>
                  </li>

                  <li>
                    <ul className="social-list">
                      {socialList?.map(({ name, href, imgSrc }) => (
                        <li key={name}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={href}
                            onClick={() => {
                              logSocialGA(name);
                            }}
                          >
                            <img
                              width="24"
                              height="24"
                              src={imgSrc}
                              alt={name}
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>

                  <li className="menu__item mega-menu-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                      className="menu__link btn white-btn"
                      onClick={() => {
                        GAEventTrack({
                          event: "googlePlay_btn",
                        });
                      }}
                    >
                      <div className="desktop-btn">
                        <img
                          src="/img/new-home/play-icon.png"
                          alt="google play"
                        />
                        Google Play
                      </div>
                      <div className="mobile-btn">
                        <img
                          src="/img/new-home/btn-google-play-black.png"
                          alt="google play"
                        />
                      </div>
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="menu__m-view-blocks" ref={menuBlocksRef}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </nav>
      </header>

      {placement !== "register" && placement !== "dashboard" && (
        <div ref={subHeaderRef} className="sub-header ">
          <div className="container">
            <ul>
              {[
                { name: "home", location: "/" },
                { name: "schools", location: "/schoollanding" },
                { name: "parents", location: "/parentlanding" },
                { name: "educators", location: "/educatorlanding" },
                {
                  name: "Practido Online Olympiad",
                  location: "/register",
                },
              ]?.map((item) => (
                <li key={item.name} className="menu__item mega-menu-item">
                  <Link
                    to={item?.location}
                    className={`btn primary-btn ${
                      path === item.location ? "active" : ""
                    } capitalize`}
                    target={item?.location === "/register" ? "_blank" : "_self"}
                  >
                    {item?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </section>
  );
};

export default Header;
